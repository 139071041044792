import React, {useState} from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ProgressBar from './ProgressBar';
import VideoFrame from '../VideoFrame';
import {DoubleArrow} from '@styled-icons/material/DoubleArrow';
import * as S from './style';

const FirstView = ({loadSecondView}) => {
    const [isReady, setReady] = useState(false);

    const onTimeUpdate = (e) => {
        if (e.percent >= 0.65 && !isReady) {
            setReady(true);
            loadSecondView(true);
        }
    }
    
    return (        
        <S.PanelWrapper>
            <S.Logo>
                <StaticImage src="../../images/logo01.png" alt="logo" />
            </S.Logo>
            <S.QuaseLa>VOCÊ ESTÁ QUASE LÁ!</S.QuaseLa>
            <S.ProgressBarWrapper>
                <ProgressBar percent="60%"/>
            </S.ProgressBarWrapper>
            <S.Steps>
                <S.Step>
                    <span>Passo 1:</span> Inscrição para o Método Esbelta Realizada!
                </S.Step>
                <S.Arrow><DoubleArrow /></S.Arrow>
                <S.Step>
                    <span>Passo 2:</span> Assista ao Vídeo Abaixo Até o Final
                </S.Step>
            </S.Steps>
            <S.VSLPanel>
                <S.VideoPanel>
                    <S.Headline><span>ATENÇÃO:</span> VÍDEO MUITO IMPORTANTE</S.Headline>
                    {/* <S.SubHeadline>Assista até o final para entender como você pode garantir a melhor absorção dos nutrientes da sua alimentação, e assim <span>turbinar seus resultados</span></S.SubHeadline> */}
                    <VideoFrame src="https://player.vimeo.com/video/678410857?h=2c42d21fca" trackTime={onTimeUpdate} />
                { isReady && <S.Button href="#products">Quero Garantir Meu Desconto Exclusivo</S.Button> }
                </S.VideoPanel>
            </S.VSLPanel>
        </S.PanelWrapper>
    );
}

export default FirstView;