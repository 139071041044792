import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as S from './style';
import { Icon, Icons } from '../../styles/sales_page';
import Timer from '../Timer';

const SecondView = () => 
    <S.ProductsPanel>
        <S.Overview>
            <S.Headline>
                Turbine o Seu Emagrecimento e Garanta Mais Saúde com o VitaLife 
            </S.Headline>
            <S.Description>
                VitaLife é um composto de vitaminas e minerais desenvolvido especialmente para mulheres. 
            </S.Description>
            <Icons>
                <Icon>
                    <StaticImage src='../../images/fatfit.jpg' alt="emagrecer" imgStyle={{borderRadius: '50%', boxShadow: '2px 2px 5px rgba(1,1,1,0.5)'}} height={300} width={300} />
                    <p style={{padding: '1rem', textAlign: 'center'}}>Potencializa o Emagrecimento </p>
                </Icon>
                <Icon>
                    <StaticImage src='../../images/imunidade.jpg' alt="emagrecer" imgStyle={{borderRadius: '50%', boxShadow: '2px 2px 5px rgba(1,1,1,0.5)'}} height={300} width={300} />
                    <p style={{padding: '1rem'}}>Reforça Sua Imunidade</p>
                </Icon>
                <Icon>
                    <StaticImage src='../../images/envelhecimento.jpg' alt="emagrecer" imgStyle={{borderRadius: '50%', boxShadow: '2px 2px 5px rgba(1,1,1,0.5)'}} height={300} width={300} />
                    <p style={{padding: '1rem'}}>Retarda o Envelhecimento</p>
                </Icon>
                <Icon>
                    <StaticImage src='../../images/cabelo2.jpg' alt="emagrecer" imgStyle={{borderRadius: '50%', boxShadow: '2px 2px 5px rgba(1,1,1,0.5)'}} height={300} width={300} />
                    <p style={{padding: '1rem'}}>Fortalece Cabelos e Unhas</p>
                </Icon>
            </Icons>
        </S.Overview>            
        <S.Warning> 
            <div className="text">Oferta Válida Somente Nesta Página e Por Tempo Limitado</div>
            <Timer initialHours={2} />
        </S.Warning>
        <S.Products id="products">
            <S.Product>
                <S.ProductHeadline>LEVE 1 POTE</S.ProductHeadline>
                <S.ProductSubHeadline>TRATAMENTO PARA 30 DIAS</S.ProductSubHeadline>
                <S.ProductImage>
                    <StaticImage  src="../../images/suplementos/vitalife.png" alt="Vitalife" />
                    <S.Discount><span>34%</span></S.Discount>
                </S.ProductImage>
                <S.Total>
                    <div>Valor Normal: <span id="de">R$147</span></div>
                    <div id="por">Nesta Oferta:</div>
                </S.Total>
                <S.Price>
                   <span>R$97</span> / pote
                </S.Price>
                <S.ButtonWrapper>
                    <S.Button href="https://go.metodoesbelta.com/pay/vitalife-upsell/1click">Comprar Agora</S.Button>
                </S.ButtonWrapper>
                <S.Bandeiras></S.Bandeiras>
                <S.Total>
                    De <span id="de">R$147</span> por <span id="por">R$97</span>
                </S.Total>
            </S.Product>
            <S.Product highlight>
                <S.Ribbon highlight>
                    <span>MAIS POPULAR</span>
                </S.Ribbon>
                <S.ProductHeadline>LEVE 2 POTES</S.ProductHeadline>
                <S.ProductSubHeadline>TRATAMENTO PARA 60 DIAS</S.ProductSubHeadline>
                <S.ProductImage>
                    <StaticImage  src="../../images/suplementos/vitalife2.png" alt="Vitalife Combo 2" />
                    <S.Discount><span>48%</span></S.Discount>
                    </S.ProductImage>
                <S.Total>
                    <div>Valor Normal: <span id="de">R$147</span></div>
                    <div id="por">Nesta Oferta:</div>
                </S.Total>
                <S.Price>
                   <span>R$77</span> / pote
                </S.Price>
                <S.ButtonWrapper>
                    <S.Button href="https://go.metodoesbelta.com/pay/vitalife-60-upsell/1click">Comprar Agora</S.Button>
                </S.ButtonWrapper>
                <S.Total>
                    Total: De <span id="de">R$294</span> por <span id="por">R$154</span>
                </S.Total>
                <S.Bandeiras></S.Bandeiras>
            </S.Product>
            <S.Product>
                <S.Ribbon>
                    <span>MELHOR NEGÓCIO</span>
                </S.Ribbon>
                <S.ProductHeadline>LEVE 3 POTES</S.ProductHeadline>
                <S.ProductSubHeadline>TRATAMENTO PARA 90 DIAS</S.ProductSubHeadline>
                <S.ProductImage>
                    <StaticImage  src="../../images/suplementos/vitalife3.png" alt="Vitalife Combo 3" />
                    <S.Discount><span>55%</span></S.Discount>
                    </S.ProductImage>
                <S.Total>
                    <div>Valor Normal: <span id="de">R$147</span></div>
                    <div id="por">Nesta Oferta:</div>
                </S.Total>
                <S.Price>
                   <span>R$67</span> / pote
                </S.Price>
                <S.ButtonWrapper>
                    <S.Button href="https://go.metodoesbelta.com/pay/vitalife-90-upsell/1click">Comprar Agora</S.Button>
                </S.ButtonWrapper>
                <S.Bandeiras></S.Bandeiras>
                <S.Total>
                    Total: De <span id="de">R$441</span> por <span id="por">R$201</span>
                </S.Total>
            </S.Product>
        </S.Products>
        <S.DenyDiscount href="https://metodoesbelta.com/upsell2">Não, Eu Não Quero Aproveitar Meu Desconto Exclusivo</S.DenyDiscount>
    </S.ProductsPanel>

export default SecondView;