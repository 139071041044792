import React, {lazy, Suspense, useState} from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import GlobalStyles from '../styles/global';
import {Layout, PageWrapper, AvisoLegal as AvisoLegalWrapper} from '../styles/sales_page';
import AvisoLegal from '../components/AvisoLegal';
import FirstView from '../components/Upsell/UpsellFirstView';
import SecondView from '../components/Upsell/UpsellSecondView';

const Upsell = () => {
    const [visible, setVisible] = useState(false);
    // const isSSR = typeof window === "undefined";
    
    return (
        <Layout>  
            <PageWrapper>
                <GlobalStyles />
                <FirstView loadSecondView={(status) => setVisible(status)}/>
                { visible && <SecondView /> }
                <AvisoLegalWrapper>
                    <AvisoLegal />
                </AvisoLegalWrapper>
            </PageWrapper>
        </Layout>    
    )
}

export default Upsell;